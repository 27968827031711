<script>
export default {
  data() {
    return {
      isDisplayError: true
    };
  }
};
</script>

<template>
  <div class="row">
    <div class="col-12">
      <b-alert v-model="isDisplayError" class="mb-4" variant="danger">{{
        $t("general.errors.dataFetchingError")
      }}</b-alert>
    </div>
  </div>
</template>
