<script>
import AuthService from "@/api/services/auth.service.js";
import appConfig from "@/app.config";
import Layout from "@/router/layouts/main";
import PageHeader from "@/shared/components/page-header";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error";
import AccessLevel from "@/shared/enums/aceessLevels.js";
import MonitoringService from "../api/monitoring.service.js";
import DisplayInfoModal from "../components/modals/info-modal.vue";
import MonitoringDataTable from "../components/widgets/monitoring-data-tabel.vue";

export default {
  page: {
    title: "Monitoring",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    DataFetchingErrorWidget,
    MonitoringDataTable,
    DisplayInfoModal
  },
  data() {
    return {
      monitoringData: null,
      /***** Data variables *****/
      loggedInUser: null,
      accessLevelEnum: AccessLevel,
      /***** Pagination Infomation *****/
      currentPage: 1,
      numberOfRespondentsPerPage: 1000,
      // DF section
      respMonitoringData: null,
      totalNumberOfRespondents: null,
      incompleteRespondents: null,
      // Resp section
      dfMonitoringData: null,
      totalNumberOfDatafiles: null,
      incompleteDatafiles: null,
      /****** Filter Options ******/
      selectedCompany: null,
      /****** Operational variable ******/
      isPageLoading: false,
      isDataFetchingError: false,
      isMonitoringDataSectionLoading: false,
      isMonitoringDataFetchingError: false,
      displaydisplayInfoModal: false,

      activatedTab: "RESPONDENT",
      fieldsForRespMonitoringDataTable: [
        {
          key: "respondentId",
          label: "Respondent ID",
          sortable: false
        },
        {
          key: "datafileId",
          label: "Datafile",
          sortable: false
        },
        {
          key: "lastUpdated",
          label: "Update on",
          sortable: true
        },
        {
          key: "progress",
          label: "Progress",
          sortable: true,
          thStyle: { width: "200px" }
        },
        {
          key: "currentStatus",
          label: "Status",
          sortable: true
        },
        {
          key: "performance",
          label: "Duration",
          sortable: false
        },
        {
          key: "actions",
          label: "Details"
        }
      ],
      fieldsForDFMonitoringDataTable: [
        {
          key: "datafileKey",
          label: "File Name",
          sortable: true
        },

        // {
        //   key: "traceId",
        //   label: "Trace",
        //   sortable: false
        // },
        {
          key: "lastUpdated",
          label: "Update on",
          sortable: true
        },
        {
          key: "progress",
          label: "Progress",
          sortable: true,
          thStyle: { width: "200px" }
        },
        {
          key: "currentStatus",
          label: "Status",
          sortable: true
        },
        {
          key: "performance",
          label: "Duration",
          sortable: false
        },
        {
          key: "actions",
          label: "Details"
        }
      ]
    };
  },
  computed: {
    computedItems() {
      var items = [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: "Jobs Monitoring",
          active: true
        }
      ];
      return items;
    }
  },
  async mounted() {
    await this.getLoginUser();
  },
  methods: {
    async closeInfoModal() {
      this.displayInfoModal = false;
    },
    async getLoginUser() {
      // 1. Enable Page Loading
      this.isPageLoading = true;

      // 2. Fetch loggedIn user Data
      try {
        this.loggedInUser = await AuthService.getLoggedInUser();
        console.log("this.loggedInUser: ", this.loggedInUser.accessLevel);
        if (
          this.loggedInUser.accessLevel === this.accessLevelEnum.SUPER_ADMIN
        ) {
          this.isPageLoading = false;
          await this.fetchMonitoringData(null, 1);
        }
      } catch (error) {
        this.isPageLoading = false;
        this.isDataFetchingError = true;
      }
    },
    async fetchMonitoringData(companyId, requestedPage) {
      try {
        // 1. Enable Section Loading
        this.isMonitoringDataSectionLoading = true;

        // 3. Fetch Monitoring Data
        const response =
          await MonitoringService.getMonitoringDataWithPagination(
            companyId,
            requestedPage,
            this.numberOfRespondentsPerPage
          );

        // 4 set values to local variables
        this.respMonitoringData = response.respondents;
        this.totalNumberOfRespondents = response.totalNumberOfRespondents;
        this.incompleteRespondents = response.incompleteRespondents;

        this.dfMonitoringData = response.datafiles;
        this.totalNumberOfDatafiles = response.totalNumberOfDatafiles;
        this.incompleteDatafiles = response.incompleteDatafiles;

        // 5. Disable section loading
        this.isMonitoringDataSectionLoading = false;
      } catch (error) {
        this.isMonitoringDataSectionLoading = false;
        this.isMonitoringDataFetchingError = true;
      }
    },
    async clickOnReloadMonitoringData() {
      await this.fetchMonitoringData(null, 1);
    },
    changeActivatedTab(tab) {
      this.activatedTab = tab;
    }
  }
};
</script>
<template>
  <Layout>
    <!-- Page Header -->
    <PageHeader title="Jobs Monitoring" :items="computedItems" />

    <!-- Main Page Loader-->
    <div v-if="isPageLoading">
      <div class="row justify-content-center mt-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>

    <!-- Main Data fetching Error -->
    <div v-if="!isPageLoading && isDataFetchingError">
      <DataFetchingErrorWidget />
    </div>

    <!-- Display Data -->
    <div
      v-if="
        !isPageLoading &&
        loggedInUser.accessLevel === accessLevelEnum.SUPER_ADMIN
      "
    >
      <div class="card">
        <div class="card-body">
          <!-- Section Loading -->
          <div class="card-text" v-if="isMonitoringDataSectionLoading">
            <div class="row justify-content-center mt-5 mb-5">
              <b-spinner
                style="width: 5rem; height: 5rem"
                class="m-2"
                variant="mid"
                role="status"
              ></b-spinner>
            </div>
          </div>

          <b-tabs
            v-if="
              !isMonitoringDataSectionLoading && !isMonitoringDataFetchingError
            "
            content-class="pt-3"
          >
            <!-- Overview Tab -->
            <b-tab
              lazy
              :active="this.activatedTab === 'RESPONDENT'"
              @click="changeActivatedTab('OVERVIEW')"
            >
              <template v-slot:title>
                <span class="d-none d-sm-inline-block">
                  Respondent Monitoring Data
                </span>
              </template>
              <!-- Display Respondent Monitoring Data in a table -->
              <div>
                <div class="card-title mb-3">
                  <div class="row">
                    <div class="col-md-10">
                      <h5>
                        Pending:
                        {{
                          `${this.incompleteRespondents} / ${this.totalNumberOfRespondents}`
                        }}
                      </h5>
                    </div>
                    <div class="col-md-2 text-right">
                      <b-button
                        variant="light"
                        @click="clickOnReloadMonitoringData()"
                        size="sm"
                      >
                        <i class="bx bx-sync mr-1"></i> Reload
                      </b-button>
                    </div>
                  </div>
                </div>
                <div class="card-text">
                  <MonitoringDataTable
                    :fieldsForMonitoringDataTable="
                      fieldsForRespMonitoringDataTable
                    "
                    :monitoringData="this.respMonitoringData"
                  />
                </div>
              </div>
            </b-tab>
            <b-tab
              lazy
              :active="this.activatedTab === 'DATAFILE'"
              @click="changeActivatedTab('DATAFILE')"
            >
              <template v-slot:title>
                <span class="d-none d-sm-inline-block">
                  Datafile Monitoring Data
                </span>
              </template>
              <!-- Display DF Monitoring Data in a table -->
              <div>
                <div class="card-title mb-3">
                  <div class="row">
                    <div class="col-md-10">
                      <h5>
                        Pending:
                        {{
                          `${this.incompleteDatafiles} / ${this.totalNumberOfDatafiles}`
                        }}
                      </h5>
                    </div>
                    <div class="col-md-2 text-right">
                      <b-button
                        variant="light"
                        @click="clickOnReloadMonitoringData()"
                        size="sm"
                      >
                        <i class="bx bx-sync mr-1"></i> Reload
                      </b-button>
                    </div>
                  </div>
                </div>
                <div class="card-text">
                  <MonitoringDataTable
                    :fieldsForMonitoringDataTable="
                      fieldsForDFMonitoringDataTable
                    "
                    :monitoringData="this.dfMonitoringData"
                  />
                </div>
              </div>
            </b-tab>
          </b-tabs>

          <!-- Monitoring Data fetching Error -->
          <div
            class="card-text"
            v-if="
              !isMonitoringDataSectionLoading && isMonitoringDataFetchingError
            "
          >
            <DataFetchingErrorWidget />
          </div>
        </div>
      </div>
    </div>

    <!-- Display Error for Non Super Admin users -->
    <div
      class="row pt-5"
      v-if="loggedInUser.accessLevel !== accessLevelEnum.SUPER_ADMIN"
    >
      <div class="col-md-3"></div>
      <div class="col-md-6 text-center">
        <img src="@/assets/images/no-access.png" alt height="50" />
        <div class="mt-3">
          {{ $t("adminPanel.noPermision") }}
        </div>
      </div>
      <div class="col-md-3"></div>
    </div>

    <!-- Nipo User Access Modal -->
    <DisplayInfoModal
      v-if="displaydisplayInfoModal === true"
      :visible="displaydisplayInfoModal"
      @close="closeInfoModal"
    />
  </Layout>
</template>